<template>
  <main class="page-markers page-new-marker">
    <PageTitleContainer>
      <PageTitle removeMargin>Nuovo livello</PageTitle>

      <div class="text-right mb-3">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'project.show' }">Indietro</router-link>
      </div>
    </PageTitleContainer>
    <div class="container">
      <ModuleForm :feedback.sync="feedback" @submitForm="submit" />
    </div>
  </main>
</template>

<script>

export default {
  name: 'module-new',
  components: {
    PageTitle: () => import('@/views/components/Layout/PageTitle'),
    PageTitleContainer: () => import('@/views/components/Layout/PageTitleContainer'),
    ModuleForm: () => import('@/views/components/Form/ModuleForm'),
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      feedback: null,
    };
  },
  methods: {
    submit (moduleForm) {
      const module = { ...moduleForm };

      this.$api.createModule(this.project.id, module)
        .then(res => {
          this.feedback = true;
          this.$router.push({ name: 'modules.show', params: { moduleId: res.data.id } });
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
};

</script>
